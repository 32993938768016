// Generated by Framer (5ac62b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withToggleVariant } from "../codeFile/VariantController.tsx";
const MotionDivWithToggleVariant = withToggleVariant(motion.div);

const cycleOrder = ["bXXVXl8o7", "YcF0oX7E5"];

const serializationHash = "framer-0ISTg"

const variantClassNames = {bXXVXl8o7: "framer-v-118w8eo", YcF0oX7E5: "framer-v-ktesa7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Off: "YcF0oX7E5", On: "bXXVXl8o7"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bXXVXl8o7"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bXXVXl8o7", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapsk70is = activeVariantCallback(async (...args) => {
setVariant("YcF0oX7E5")
})

const onTap1wbrr83 = activeVariantCallback(async (...args) => {
setVariant("bXXVXl8o7")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0ISTg", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><MotionDivWithToggleVariant {...restProps} className={cx("framer-118w8eo", className)} data-framer-name={"On"} data-highlight layoutDependency={layoutDependency} layoutId={"bXXVXl8o7"} onTap={onTapsk70is} ref={ref ?? ref1} style={{backgroundColor: "var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0))", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, ...style}} variants={{YcF0oX7E5: {backgroundColor: "var(--token-58389c29-c015-4427-a57c-637e65cb6404, rgb(227, 227, 227))"}}} {...addPropertyOverrides({YcF0oX7E5: {"data-framer-name": "Off", onTap: onTap1wbrr83}}, baseVariant, gestureVariant)}><motion.div className={"framer-14oribh"} layoutDependency={layoutDependency} layoutId={"MQTfJMuRy"} style={{borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50}}/><motion.div className={"framer-1ob8bmb"} layoutDependency={layoutDependency} layoutId={"UVyR_WkHt"} style={{backgroundColor: "var(--token-004153e9-836e-4588-87f8-07c9aa18e03d, rgb(250, 250, 250))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.1)"}}/></MotionDivWithToggleVariant></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0ISTg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0ISTg .framer-7vqiwp { display: block; }", ".framer-0ISTg .framer-118w8eo { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 4px 4px 4px 4px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-0ISTg .framer-14oribh, .framer-0ISTg .framer-1ob8bmb { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: hidden; position: relative; width: 16px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0ISTg .framer-118w8eo { gap: 0px; } .framer-0ISTg .framer-118w8eo > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-0ISTg .framer-118w8eo > :first-child { margin-left: 0px; } .framer-0ISTg .framer-118w8eo > :last-child { margin-right: 0px; } }", ".framer-0ISTg.framer-v-ktesa7 .framer-14oribh { order: 1; }", ".framer-0ISTg.framer-v-ktesa7 .framer-1ob8bmb { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"YcF0oX7E5":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerrorFsvs2q: React.ComponentType<Props> = withCSS(Component, css, "framer-0ISTg") as typeof Component;
export default FramerrorFsvs2q;

FramerrorFsvs2q.displayName = "Utility / Toggle";

FramerrorFsvs2q.defaultProps = {height: 24, width: 40};

addPropertyControls(FramerrorFsvs2q, {variant: {options: ["bXXVXl8o7", "YcF0oX7E5"], optionTitles: ["On", "Off"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerrorFsvs2q, [])